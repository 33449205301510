<template>
	<div class="container">
		<LoginWrap v-if="showLogin"></LoginWrap>
		<div class="zhanwei20"></div>
		<div class="create-sec">
			<input placeholder="标题" @input="titleChange" v-model="title" class="title-input" />
			<div class="project-input" @click.stop="actTopic">{{topicIndex>-1?TopicData.list[topicIndex].name:'提案'}}
			</div>
			<!-- <input placeholder="项目" disabled @click="actTopic()" :value="topicIndex==-1?'':TopicData[index].name" class="project-input" /> -->
			<div class="progress-sec" v-if="isTian">
				<div class="flex-row align-center space-between progress-item">
					<div class="flex-row align-center">
						<div class="progress-text">目前</div>
						<div class="progress-line"></div>
					</div>
					<div class="progress-num">0%</div>
				</div>
				<div class="flex-row align-center space-between progress-item">
					<div class="flex-row align-center">
						<div class="progress-text">目标</div>
						<div class="progress-line-active"></div>
					</div>
					<div class="progress-num">20%</div>
				</div>

				<div class="flex-row align-center deadline-sec">
					<div class="deadline-text">截止时间：</div>
					<div class="deadline-date">30天</div>
				</div>
				<div class="succ-text">获得20%的持有者支持后，提案成功，将发起投票。</div>
			</div>

			<!-- <textarea ref="mytextarea" :style="'bottom:'+ (show== 0?'-9999px':'0')" name="" id="" cols="30" rows="3"
				placeholder="请输入" class="for-textarea"></textarea> -->
			<!-- <div class="edit" @click.stop="showA">富文本编辑区域</div> -->
			<Editor style="margin-top: 15px;"></Editor>
		</div>
		<van-popup v-model="showTopic" round position="bottom">
			<van-picker :loading="TopicData.loading" title="选择项目" show-toolbar :columns="TopicData.columns"
				@confirm="onConfirm" @cancel="onCancel" @change="onChange" />
		</van-popup>
		<div class="submit-btn" @click="commit()">提交</div>
	</div>
</template>
<script>
	import config from '../config/config.js';
	import util from '../config/util.js';
	import Vue from 'vue';
	import Vant from 'vant';
	import 'vant/lib/index.css';
	import Editor from '@/components/Editor.vue';
	import LoginWrap from '@/components/Login.vue';
	Vue.use(Vant);
	export default {
		data() {
			return {
				isTian: false, //话题和提案写在了同一个页面，不行的话你再分开
				title: '', //投票标题
				desc: '', //补充描述
				show: 0,
				showTopic: false,
				TopicData: {
					loading: false,
					error: false,
					finished: false,
					pageNum: 1,
					pageSize: 50,
					list: [],
					immediateCheck: false,
					columns: []
				},
				topicIndex: -1,
				content: '',
				showLogin: false,
				id: '',
				name: ''
			}
		},
		components: {
			Editor,
			LoginWrap
		},
		mounted() {
			this.id = this.$route.query.projectId
			this.name = this.$route.query.name
			this.getNftProjectList();
		},
		methods: {
			login() {
				this.showLogin = true;
			},
			closeLogin() {
				this.showLogin = false;
			},
			onConfirm(value, index) {
				console.log(index)
				this.topicIndex = index;
				this.showTopic = false;
			},
			onCancel() {
				this.showTopic = false;
				console.log('onCancel')
			},
			onChange(picker, value, index) {
				if (index + 1 >= this.TopicData.columns.length) {
					this.getNftProjectList();
				}
			},
			getNftProjectList() {
				const that = this;
				console.log(that.TopicData)
				if (that.TopicData.finished || that.TopicData.loading) return;
				that.TopicData.loading = true;
				let params = new FormData()
				params.append('pageNum', this.TopicData.pageNum)
				params.append('pageSize', this.TopicData.pageSize)
				that.axios.post(config.requestUrl + '/front/forum/getNftProjectList', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast.clear();
						let list = that.TopicData.list;
						let newList = response.data.obj.list;

						list = newList;
						if (list.length < that.TopicData.pageSize) that.TopicData.finished = true;
						that.TopicData.pageNum = that.TopicData.pageNum + 1;
						that.TopicData.loading = false
						that.TopicData.list = list;


						let columns = that.TopicData.columns;
						let newColumns = []
						let currentIndex = -1;
						for (let i in newList) {
							if (newList[i].id == that.id) {
								currentIndex = i
							}
							columns.push(newList[i].name)
						}
						console.log(currentIndex)
						that.topicIndex = currentIndex > -1 ? currentIndex : 0
						that.TopicData.columns = columns
					} else {
						that.TopicData.error = true
						that.$toast.fail(response.data.msg);
					}
					that.TopicData.loading = false
				}, response => {
					that.TopicData.error = true
					that.TopicData.loading = false
					that.$toast.fail('获取失败');
				})
			},
			setContent(data) {
				this.content = data;
				console.log(this.content)
			},
			actTopic() {
				this.showTopic = true;
			},
			showA() {
				this.show = 1;
				this.$refs.mytextarea.focus();

			},
			titleChange(e) {
				this.title = e.target.value;
			},
			descChange(e) {
				this.desc = e.target.value;
			},
			commit() {
				const that = this;
				if (!that.$store.state.user.token) {
					that.login();
					return;
				}
				if (!that.title.trim()) {
					that.$toast('请输入标题')
					return;
				}
				if (!that.content.trim()) {
					that.$toast('请输入内容')
					return;
				}

				// 提交数据到后台 todo
				let params = new FormData();
				params.append('title', that.title)
				params.append('content', that.content)
				params.append('projectId', that.TopicData.list[that.topicIndex].id)
				that.$toast.loading({
					message: '话题提交中...',
					forbidClick: true,
					duration: 0,
				});
				that.axios.post(config.requestUrl + '/front/forum/publishTopic', params, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'token': that.$store.state.user.token
					}
				}).then(response => {
					if (response.data.code == 0) {
						that.$toast('发表成功')
						// that.$toast.close();
						setTimeout(() => {
							that.$router.go(-1)
						}, 1200)
						// this.$router.replace('/topicdetail?id=' + id)
						console.log(1)
						// let id = 1;
					} else {
						that.$toast(response.data.msg);
					}
				}, response => {
					that.$toast('发表话题失败，请稍后重试');
				})

			},
		}
	}
</script>


<style scoped="scoped">
	.container {
		width: 100%;
		height: 100%;
		background: #F5F5F5;
	}

	.zhanwei20 {
		height: 20px;
	}

	.create-sec {
		width: 710px;
		min-height: 1262px;
		background: #FFFFFF;
		border-radius: 10px;
		margin: 0 auto;
		padding: 0 20px 30px 20px;
		box-sizing: border-box;
	}

	.title-input {
		width: 650px;
		height: 110px;
		border: none;
		/* border-bottom: 1px solid #F5F5F5; */
		border-bottom: 1px solid #999;
		font-size: 36px;
		color: #000000;
		line-height: 110px;
	}

	.project-input {
		width: 650px;
		height: 110px;
		border: none;
		border-bottom: 1px solid #F5F5F5;
		font-size: 36px;
		color: #000000;
		line-height: 110px;
	}

	.edit {
		width: 650px;
		height: 884px;
		background: #FFFFFF;
		border-radius: 10px;
		border: 1px solid rgba(0, 0, 0, 0.1);
		margin-top: 30px;
	}

	/* 提案进度部分开始 */
	.progress-sec {
		width: 650px;
		height: 260px;
		background: rgba(124, 114, 161, 0.08);
		border-radius: 10px;
		margin-top: 20px;
		padding: 10px 30px 0 30px;
		box-sizing: border-box;
	}

	.progress-item {
		padding-top: 20px;
	}

	.progress-text {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
	}

	.progress-line {
		width: 418px;
		height: 20px;
		background: #FFFFFF;
		border-radius: 15px;
		margin-left: 22px;
	}

	.progress-num {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
	}

	.progress-line-active {
		width: 418px;
		height: 20px;
		background: #6236FF;
		border-radius: 15px;
		margin-left: 22px;
	}

	.deadline-sec {
		margin-top: 20px;
	}

	.deadline-text {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.5);
		line-height: 30px;
	}

	.deadline-date {
		font-size: 28px;
		color: rgba(0, 0, 0, 0.85);
		line-height: 30px;
		margin-left: 10px;
	}

	.succ-text {
		width: 589px;
		height: 46px;
		background: #FFFFFF;
		border-radius: 23px;
		font-size: 24px;
		color: #584891;
		line-height: 46px;
		text-align: center;
		margin-top: 27px;
	}

	/* 提案进度部分结束 */
	.submit-btn {
		width: 690px;
		height: 80px;
		background: #6236FF;
		border-radius: 40px;
		text-align: center;
		font-size: 32px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 80px;
		letter-spacing: 1px;
		margin: 30px auto 0 auto;
	}

	.for-textarea {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 750px;
		height: 200px;
	}
</style>